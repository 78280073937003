'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./search/search'));
    processInclude(require('./search/mobileFilters'));
    processInclude(require('./product/wishlist'));
    // processInclude(require('./product/quickView'));
    require('./product/imageSlider').init();
    require('./search/einsteinSlider').init();
});
