'use strict';

const customCarousel = require('../customCarousel');
const Wishlist = require('../product/wishlist');
const EinsteinSlider = require('../product/einsteinSlider');

const init = () => {
    // initiate recommendation slider
    var recomGlider = document.querySelector('.recommendation-glider');
    if (recomGlider) {
        customCarousel.create(recomGlider);
        // initiate product wishlist icons
        Wishlist.initProducts();
    }
    // listen to einstein slider mutation
    EinsteinSlider.initMutationObserver('.plp-recom');
};

module.exports = {
    init: init
};
