'use strict';

const baseSearch = require('base/search/search');

const urlUtils = require('../util/url');

baseSearch.filter = () => {
    $('.container').on('click', 'button.filter-results', function () {
        $('.refinement-bar').show();
        $('.refinement-bar').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
        $('.refinement-bar .close').focus();
    });
};
baseSearch.closeRefinements = () => {
    $('.container').on('click', '.refinement-bar button.close, .modal-background', function () {
        $('.refinement-bar').hide();
        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    });
};
module.exports = baseSearch;

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 */
function handleRefinements($results) {
    $('.refinement').each(function () {
        const $header = $(this).children('.refinement-header');
        const $content = $(this).children('.content');
        const $target = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));

        $target.children('.refinement-header').attr('aria-expanded', $header.attr('aria-expanded'));
        $target.children('.content').removeClass().addClass($content[0].classList.toString());
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 */
function parseResults(response) {
    const $results = $(response);

    const count = Number($results.find('[data-count]').data('count'));

    if (count === 0) {
        throw new Error('NO_RESULTS');
    }

    const specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.show-results-button',
        '.page-size-selector'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    $(document).trigger('refresh:custom-select');
}

/**
 * Update sort option URLs
 */
function updateSortOptions() {
    const sortOptions = $('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Update result count text
 */
function updateResultCount() {
    const sortCountHtml = $('.grid-footer .footer-result-count').html();
    $('.result-count').html(sortCountHtml);
}

/**
 * Appends sorting parameters to the URL based on the current sorting selection
 * @param {string} url - current URL
 * @return {string} URL with sorting rule appended
 */
function appendSortingRuleToUrl(url) {
    var sortOrderElement = $$('[name="sort-order"] option[selected]');

    if (!sortOrderElement) { // Nothing is selected, so use the first item in the list
        sortOrderElement = $$('[name="sort-order"] option');
    }

    if (sortOrderElement) {
        return urlUtils.addOrReplaceQueryParameters(url, { srule: sortOrderElement.dataset.id });
    }

    return url;
}

/**
 * Appends page size parameters to the URL. If parameter pageSize is passed, it will be used to append it to the URL.
 * Otherwise, it will fetch page size from page size selector.
 * @param {string} url - current URL
 * @param {number} pageSize - Page size to append (optional)
 * @return {string} URL with pageSize appended or unmodified URL if no pageSize was selected
 */
function appendPageSizeToUrl(url, pageSize) {
    /* eslint-disable no-param-reassign */
    pageSize = pageSize || $('.page-size-selector input:checked').val();
    if (pageSize) {
        url = urlUtils.addOrReplaceQueryParameters(url, {
            start: 0,
            sz: pageSize
        });
    }
    /* eslint-enable no-param-reassign */
    return url;
}

/**
 * Makes a call to the given url
 * @param {string} url - URL to call
 * @return {Promise} promise
 */
function doSearch(url) {
    $.spinner().start();
    const data = { page: $('.grid-footer').data('page-number'), selectedUrl: url };
    return $.get(url, data).then(response => {
        parseResults(response);
        $(document).trigger('search:filter');
    }).always(() => $.spinner().stop());
}


/**
 * Makes a call to this search refinement URL and parses response
 * @param {string} url - Search refinement URL
 * @return {Promise} promise
 */
function refineSearch(url) {
    /* eslint-disable no-param-reassign */
    url = appendSortingRuleToUrl(url);
    url = appendPageSizeToUrl(url);
    /* eslint-enable no-param-reassign */

    $.spinner().start();
    const data = { page: $('.grid-footer').data('page-number'), selectedUrl: url };
    return $.get(url, data).then(response => {
        parseResults(response);
        history.pushState({ reapplyFilters: true }, document.title, $('.permalink').val());
        $(document).trigger('search:filter');
        if ($('.filter-results').is(':visible')) {
            if (!$('.refinement-bar').is(':visible')) {
                $('.show-results-button').click();
            }
        }
    }).always(() => $.spinner().stop());
}

// Copy the whole function just to trigger the 'search:showMore' event AFTER the AJAX call has been made
module.exports.showMore = () => {
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        e.preventDefault();
        const showMoreUrl = $(this).data('url');

        $.spinner().start();
        $.get(showMoreUrl, { selectedUrl: showMoreUrl }, response => {
            $('.grid-footer').replaceWith(response);
            updateSortOptions();
            updateResultCount();
            history.pushState({ reapplyFilters: true }, document.title, $('.permalink').val());
            $(document).trigger('search:showMore');
        }).always(() => $.spinner().stop());
    });
};

module.exports.applyFilter = () => {
    // Handle refinement value selection and reset click
    const $container = $('.container');

    const handle = e => {
        e.preventDefault();
        e.stopPropagation();
        refineSearch(e.target.href || e.target.dataset.href);
    };

    $container.on('click', '.refinements li button, .reset, .filter-value button, .swatch-filter button', handle);
    $container.on('change', '.refinements li .refinement-checkbox', handle);
};

module.exports.priceRefinement = () => {
    const slider = require('./refinementSlider');
    const isEqual = require('lodash/isEqual');

    let selectedRange = null;

    const createSlider = () => {
        const priceSlider = $$('.price-slider');
        if (!priceSlider) {
            return;
        }

        const $noResultsMessage = $(priceSlider).next('.no-results-message');

        if ((priceSlider.dataset.min !== 'null') && (priceSlider.dataset.max !== 'null')) {
            let min = Math.floor(Number(priceSlider.dataset.min));
            let max = Math.ceil(Number(priceSlider.dataset.max));

            if ((max - min) <= 1) {
                $(priceSlider).closest('.refinement').addClass('d-none');
                return;
            }

            if (!selectedRange) {
                selectedRange = [min, max];
            }

            slider.create(priceSlider, min, max, selectedRange, (url, range, element) => {
                if (isEqual(selectedRange, range)) {
                    return;
                }

                let lastRange = selectedRange;
                selectedRange = range;

                refineSearch(url).fail(() => {
                    // No results, reset slider
                    element.noUiSlider.set(lastRange);
                    selectedRange = lastRange;

                    $noResultsMessage.clearQueue().animate({ opacity: 100 }).delay(2000).animate({ opacity: 0 });
                });
            });
        }
    };

    createSlider();

    $(document).on('search:filter', createSlider);

    $('.container').on('click', '.reset', () => {
        selectedRange = null;
    });
};

module.exports.pageSizeSelector = () => {
    $('.container').on('change', '.page-size-selector input', function (e) {
        let url = $(e.target).closest('.page-size-selector').data('url');
        /* eslint-disable no-param-reassign */
        url = appendSortingRuleToUrl(url);
        url = appendPageSizeToUrl(url, e.target.value);
        /* eslint-enable no-param-reassign */

        $.spinner().start();
        $.get(url, { selectedUrl: url }, response => {
            $('.product-grid').empty().html(response);
            updateSortOptions();
            updateResultCount();
            history.pushState({ reapplyFilters: true }, document.title, $('.permalink').val());
            $(document).trigger('search:showMore');
        }).always(() => $.spinner().stop());
    });
};

module.exports.initHistory = () => {
    let previousHistoryState;
    window.addEventListener('popstate', () => {
        if ((history.state && history.state.reapplyFilters)
            || (previousHistoryState && previousHistoryState.reapplyFilters)) {
            previousHistoryState = history.state;
            doSearch(window.location.href);
        }
    });
};
