'use strict';

module.exports = {/**
     * Adds or replaces the parameters in the URL.
     * If the parameter was already present, it will replace the value.
     * If not, it will append this parameter and its value.
     *
     * @param {string} url - URL to transform
     * @param {Object} params - Parameters to append or replace
     * @return {string} - result URL with the appended and replaced paramters
     */
    addOrReplaceQueryParameters: function (url, params) {
        var newUrl = url;
        Object.keys(params).forEach((key) => {
            const regExp = new RegExp(`((&|\\?)${key}=).*?(&|$)`);
            if (newUrl.match(regExp)) {
                newUrl = newUrl.replace(regExp, `$1${encodeURIComponent(params[key])}$3`);
            } else {
                newUrl = newUrl + (newUrl.includes('?') ? '&' : '?') + `${key}=${encodeURIComponent(params[key])}`;
            }
        });

        return newUrl;
    }
};
