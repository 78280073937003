const noUiSlider = require('nouislider');
const urlUtils = require('../util/url');

module.exports.create = function (element, min, max, startRange, callback) {
    if (!element || element.noUiSlider) {
        return;
    }

    noUiSlider.create(element, {
        start: startRange,
        step: 1,
        margin: 1,
        connect: true,
        range: { min, max },
        behaviour: 'none'
    });

    const $html = $('html');
    const priceFormatter = new Intl.NumberFormat($html.attr('lang'), {
        style: 'currency',
        currency: $html.data('currency')
    });

    element.noUiSlider.on('update', () => {
        let range = element.noUiSlider.get();
        $('.slider-labels .min').text(priceFormatter.format(range[0]));
        $('.slider-labels .max').text(priceFormatter.format(range[1]));
    });

    element.noUiSlider.on('change', () => {
        let [currentMin, currentMax] = element.noUiSlider.get().map(Number);

        /**
         * Default refinement URL has the form of "/Search-ShowAjax?cgid=shop&pmin=0.0&pmax=10.0".
         * So we need to replace pmin and pmax values with slider values.
         *
         * It can happen that the URL comes without parameters after you select the exact same range of the configured
         * bucket (then it becomes a reset URL). But we don't want that, so always append parameters in that case.
         */
        let url = element.dataset.href;
        url = urlUtils.addOrReplaceQueryParameters(url, {
            pmin: currentMin,
            pmax: currentMax
        });

        callback(url, [currentMin, currentMax], element);
    });
};
