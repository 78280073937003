'use strict';

module.exports.mobileFilters = () => {
    var filterHeight; // this height is set depending on the the viewbox of the device used.
    // TODO: instead of using jquery to generate the filter boxes create a template and call this template to generate them.
    /**
     * append a filter item to the latest row
     * @param {string} filterName filter block
     * @param {boolean} isLeftCollumn check to see if next filter is left collumn
     */
    function appendFilter(filterName, isLeftCollumn) {
        var border = isLeftCollumn ? 'border' : 'border-bottom border-right border-top';
        if ($('#mobile' + filterName).length === 0) {
            if (filterName === '' && $('#emptyFilter').length === 0) { // incase the amount of filters are uneven create an empty filterblock.
                $('#filterSearch').append('<div id="emptyFilter" class="col-6 d-md-none p-1 text-center mobile-active-filter d-flex ' + border + '"><p class="col-3 mt-2 mb-1"> </p></div>');
                $('#emptyFilter').height(filterHeight);
            }
            if (filterName !== '') { // create filter box with filtername and a remove button with click event
                $('#filterSearch').append('<div id="mobile' + filterName + '" class="col-6 d-md-none p-1 text-center mobile-active-filter d-flex ' + border + '"><button id="' + filterName + 'button" class="stickyClosebutton close-icon icon icon-small icon-cross float-left col-3 mt-2"></button><p class="col-3 mt-2 mb-1">' + filterName + '</p></div>');
                filterHeight = $('#mobile' + filterName).height();
                $('#' + filterName + 'button').on('click', function () {
                    $('#boolean-attribute-refinement-' + filterName).click();
                });
            }
        }
    }

    /**
     * looks for any active refinement options
     */
    function checkActiveFilters() {
        var activeRefinements = [];

        for (let i = 0; i < $('[id^=boolean-attribute-refinement-]').length; i++) {
            var filter = $('[id^=boolean-attribute-refinement-]')[i].id;
            if ($('#' + filter).is(':checked')) {
                // add any active filters to array to append them later => this way it should load even on redirect downside all filters are checked
                activeRefinements.push($('#' + filter));
            }
        }
        for (let i = 0; i < activeRefinements.length; i++) {
            var activeFilter = activeRefinements[i];
            appendFilter(activeFilter.attr('id').substring(29), i % 2 === 0 || i === 0);
        }
        if (activeRefinements.length % 2 !== 0) {
            appendFilter('');
        }
    }

    const $container = $('.container');
    $container.on('click', '.refinement-bar button.show-results-button', () => {
        $('.refinement-bar button.close').click();
        checkActiveFilters(); // on button click load the active filters
    });

    const Responsive = require('../util/responsive');

    let activeFilter;
    const backButtonSelector = '.refinement-bar .btn-back';
    const resetLinkSelector = '.refinement-bar .reset.link';
    const selectedFilterTitleSelector = '.filter-header .title';

    // Click on a filter
    $container.on('click', '.refinement-bar .refinement a', (e) => {
        if (Responsive.isMobile()) {
            e.preventDefault();
            $('.refinement > .content.open').removeClass('open');
            activeFilter = $(e.currentTarget).next('.content').addClass('open show').attr('id');
            $(e.currentTarget).parents('.refinements').addClass('filter-open');
            $(backButtonSelector).removeClass('d-none');
            $(selectedFilterTitleSelector).text(e.currentTarget.textContent);
        }
    });

    // Click on back button or reset filters
    $container.on('click', `${backButtonSelector}, ${resetLinkSelector}`, () => {
        if (Responsive.isMobile()) {
            $('.refinement > .content').removeClass('open').parents('.refinements').removeClass('filter-open');
            $(backButtonSelector).addClass('d-none');
            const $title = $(selectedFilterTitleSelector);
            $title.text($title.data('default-title'));
            activeFilter = null;
        }
    });

    // Filter applied, keep same filter open
    $(document).on('search:filter', () => {
        if (Responsive.isMobile() && activeFilter) {
            $(`#${activeFilter}`).addClass('open');
            $(backButtonSelector).removeClass('d-none');
        }
    });

    $(document).ready(function () {
        checkActiveFilters(); // check for active filters on page load
    });
};
